(() => {
  const hideFullHeaderClass = 'hide-full-header';
  const headerHeight = '90px';
  const headerHeightWithLogos = '210px';
  let menuOpenButton;

  // shows/hides the logo menu and the brand bar based on user scroll action
  const handleScroll = () => {
    const header = document.querySelector('.header');
    if (header === null) {
      return;
    }
    let prevScrollPosition = 0;
    const { body } = document;
    const root = document.documentElement;
    document.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY;
      if (
        scrollPosition < header.clientHeight ||
        scrollPosition < prevScrollPosition
      ) {
        body.classList.remove(hideFullHeaderClass);
        root.style.setProperty('--scroll-padding', headerHeight);
      } else {
        root.style.setProperty('--scroll-padding', headerHeightWithLogos);
        body.classList.add(hideFullHeaderClass);
      }
      prevScrollPosition = scrollPosition;
    });
  };

  // scrolls to the section of the page when nav items are clicked
  const handleNavElements = () => {
    const header = document.querySelector('.header');
    const anchors = header?.querySelectorAll(
      '.emu-navigation__item a'
    ) as NodeListOf<HTMLAnchorElement>;

    if (anchors?.length) {
      let isHomePage = Array.from(anchors).some(el => {
        const href = el.getAttribute('href')?.split('#')[1];
        return href && document.getElementById(href);
      });

      if (isHomePage) {
        const root = document.documentElement;
        anchors.forEach(anchor => {
          const elId = anchor.getAttribute('href')?.split('#')[1];
          const scrollToEl =
            elId && (document.querySelector(`#${elId}`) as HTMLElement);
          if (scrollToEl) {
            anchor.addEventListener('click', e => {
              e.preventDefault();
              // collapsing the menu if it is open
              if (menuOpenButton?.classList?.contains('js-toggle-on')) {
                menuOpenButton?.click();
              }

              // scrolling to the element
              if (scrollToEl.offsetTop < window.scrollY) {
                root.style.setProperty(
                  '--scroll-padding',
                  headerHeightWithLogos
                );
              } else {
                root.style.setProperty('--scroll-padding', headerHeight);
              }
              scrollToEl.scrollIntoView();
            });
          }
        });
      }
    }
  };

  // handling the menu open and close in the mobile view
  const handleMobileMenu = () => {
    menuOpenButton = document.querySelector(
      '.header__menu-open'
    ) as HTMLButtonElement;

    const bodyElement = document.body as HTMLElement;

    window.Bus.on('emu-button:click', ({ id, toggleOn }) => {
      // when close button is clicked, click the open button
      if (id === 'header__menu-close') {
        menuOpenButton?.click();
      } else if (id === 'header__menu-open') {
        bodyElement.classList.toggle('mobile-hide-overflow', toggleOn);
      }
    });
  };

  const handleISIHover = () => {
    const root = document.documentElement;
    // console.log(document.querySelector('#show-safety-info-btn'));
    window.AAAEM?.utilities
      ?.waitForElement('#show-safety-info-btn')
      .then(el => {
        el?.addEventListener('mouseover', () => {
          root.style.setProperty('--scroll-padding', headerHeight);
        });

        el?.addEventListener('touchend', () => {
          root.style.setProperty('--scroll-padding', headerHeight);
        });
      });
  };

  const init = () => {
    handleMobileMenu();
    handleScroll();
    handleNavElements();
    handleISIHover();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
