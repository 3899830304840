(() => {
  // adds click event to card, to handle the active states.
  const makeCardsFlippable = wrapper => {
    const cards = wrapper?.querySelectorAll('.flippable-card');

    if (cards?.length) {
      cards.forEach(item => {
        item?.addEventListener('click', () => {
          item?.classList.toggle('flippable-card--click-active');
        });
      });
    }
  };

  // This function will handle hover/click functionality for cards
  const handleFlipCard = () => {
    const flipCardWrappers = document.querySelectorAll(
      '.flippable-cards-wrapper'
    );
    flipCardWrappers.forEach(el => {
      makeCardsFlippable(el);
    });
  };

  const init = () => {
    handleFlipCard();
  };

  if (document.readyState === 'loading') {
    window.addEventListener('load', init);
  } else {
    init();
  }
})();
